import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule } from './components/components.module';
import { PipesModule } from './pipes/pipes.module';


import { IonicStorageModule } from '@ionic/storage-angular';
import { HttpClientModule } from '@angular/common/http';
import { BrMaskerModule } from 'br-mask';


//CORDOVA PLUGINS
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer/ngx';






@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ mode: "ios" }),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    ComponentsModule,
    PipesModule,
    HttpClientModule,
    BrMaskerModule,
  ],
  providers: [
    { 
      provide: RouteReuseStrategy, 
      useClass: IonicRouteStrategy 
    },
    FileOpener,
    File,
    FingerprintAIO,
    PhotoViewer,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
