import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-checklist-done',
  templateUrl: './checklist-done.component.html',
  styleUrls: ['./checklist-done.component.scss'],
})
export class ChecklistDoneComponent implements OnInit {


  @Input()ot: any = {
    fechaInicio:"",
    por:"",
    notas: ""
  }
  
  @Input() color:string = 'secondary'
  @Input() hide: boolean = true

  @Input()checklist: Array<any> = [];

  constructor() { }

  ngOnInit() {}

}
