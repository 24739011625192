import { Permisos } from "../interfaces/interfase_user";


export class ModelUser {

    _id      : string;
    firma    : string;
    nombre   : string;
    puesto   : string
    email    : string;
    imagen   : string;
    telefono : string;
    rol      : string;
    permisos : Permisos;
    token    : string;

    constructor(
        _id?      : string,
        firma?    : string,
        puesto?   : string,
        nombre?   : string,
        imagen?   : string,
        email?    : string,
        permisos? : Permisos,
        rol?      : string,
        token?    : string
    ) {
        this.firma    = "",
        this.nombre   = "",
        this.puesto   = "",
        this.email    = "",
        this.rol      = "",
        this.token    = "",
        this.permisos = {

            activo: false,
            superUser: false,
        
                acceso:{
                    mobil:false,
                    web  : false,
                },
        
                cotizador: {
        
                    cotizaciones : {
                        acceso : false,
                        nuevo  : false,
                    },
                    ajustes : {
                        acceso  : false,
                    }
                },
        
                servicio_externo: {
        

                    checklist: {
                        acceso  : false,
                        nuevo : false,
                    },
        
                    solicitud: {
                        acceso   : false,
                        nuevo     : false,
                        eliminar  : false,
                        actualizar: false,
                    },
        
                    asignaciones: {
                        acceso     : false,
        
                        sin_aprobar: {
                            acceso : false,
                            rechazar: false,
                            aprobar : false,
                        },
        
                        pesos: {
                            acceso : false,
                            aprobar: false,
                        },
        
                        sin_asignar: {
                            acceso : false,
                            aprobar: false,
                        },
        
                        asignados: {
                            acceso    : false,
                            desasignar: false,
                            reasignar : false,
                        },
        
                        incompletos: {
                            acceso  : false,
                            aprobar : false,
                            rechazar: false,
                        }
                    },
        
                    proceso: {
                        acceso  : false, 
                        reporte: {
                            editar: false,
                            eliminar: false,
                        }
                    },
                    archivados: {
                        acceso        : false,
                        editar_reporte: false,
                    },
                    seguimiento: {
                        acceso    : false,
                        crear_sst :false
                    },
        
                    metricas: {
                        active: false
                    },
                    precio: {
                        ver:false
                    },
                    pesas: {
                        acceso:false,
                        liberar:false
                    },
                    bodega:{
                        acceso      :false,
                        nuevo       :false,
                        herramientas:false,
                        repuestos   :false,
                        consumibles  :false,
                    },
                    ajustes: {
                        acceso: false,
                        equipos: {
                            accesos: false,
                            nuevo: false,
                            editar: false,
                            eliminar: false, 
                        },
                        servicios: {
                            accesos: false,
                            nuevo: false,
                            editar: false,
                            eliminar: false,
                        },
                        flotilla:{
                            accesos: false,
                            nuevo: false,
                            eliminar: false,
                        }
                    } 
                },
                administrador: {
                    acceso   : false,
        
                    usuarios  : {
                        acceso: false,
                        nuevo: false,
                        editar: false,
                        eliminar: false,
                    },
                    cursos: {
                        acceso: false,
                        usuario : {
                            nuevo: false,
                            editar: false,
                            eliminar: false,
                        },
                        cliente: {
                            nuevo: false,
                            eliminar: false,
                        }
                    },
                    clientes  : { 
                        acceso   : false,
                        nuevo    : false,
                        editar   : false,
                        eliminar : false,
                    }
                }
        }
          
    }
   }