import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firma'
})
export class FirmaPipe implements PipeTransform {

  transform(value: boolean): string {
    if (!value) {
      return 'No aún'
    } else {
      return "Si"
    }
  }

}
