import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ServiceText {

  constructor() { }

  public upperEachWord(text: string): string {
    if (text == "") {
      return ""
    } else {
      let result = "";
      const words = text.split(" ");
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }
      result = words.join(" ");
      return result;
    }
  }

  shortMonthNames = [
    "Ene", "Feb", "Mar",
    "Abr", "May", "Jun", "Jul",
    "Ago", "Set", "Oct",
    "Nov", "Dic"];


  getHour(dat: string) {
    let date = new Date(dat);
    var hours = date.getHours();
    var minutes: any = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  dateStrToDateHoure(date: string): string {
    const datex = new Date(date);
    return `${datex.getDate()} ${this.shortMonthNames[datex.getMonth()]} ${datex.getFullYear()} - ${this.getHour(date)}`;
  }


  generarCodigo (length:number):string {
    var result           = '';
    var characters       = 'abcdefghijklmnopqrstuvwxyz123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }


 async base64ToData(base64: string):Promise<FormData> {
   let blob:any = await this.dataURItoBlob('data:image/jpeg;base64,' + base64);
   let formData = new FormData(); 
   formData.append('file',blob,"testeo.jpeg");
   return formData
 }



 async dataURItoBlob(dataURI:any) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString:any;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
  else
      byteString = unescape(dataURI.split(',')[1]);
  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
      ia[i] = await byteString.charCodeAt(i);
  }
  return new Blob([ia], {type:mimeString});
}

generateHexId_other() {
  const hexId = Array.from(window.crypto.getRandomValues(new Uint8Array(12)))
    .map(b => b.toString(16).padStart(2, '0'))
    .join('');
  return hexId;
}

generateHexId() {
  let hexString = "";
  for (let i = 0; i < 24; i++) {
    const randomNum = Math.floor(Math.random() * 16);
    hexString += randomNum.toString(16);
  }
  return hexString;
}



StringToNumero(str: string): number {
  const strSinComas = str.toString().replace(/,/g, '');
  const numero = parseFloat(strSinComas);
  return numero;
}



}
