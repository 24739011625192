import { Injectable } from '@angular/core';
import { ModelUser } from '../model/models/model_user';
import { Device } from '@capacitor/device';

@Injectable({
  providedIn: 'root'
})
export class ServiceLoginUser {

  userIsLogin: boolean = false;
  userData: ModelUser = new ModelUser()


  notas = [];

  constructor() {
  }

  buildSideMenu(user: ModelUser) { 
    this.userIsLogin = true;
    this.userData = user;
  }

  closeSesion(){
    this.userIsLogin = false;
    this.userData = new ModelUser();
  }

  async getUUID():Promise<string>{
    const UUID = await Device.getId();
    if (UUID.identifier) {
      return UUID.identifier;
    }else{
      return "";
    }
  }
}
