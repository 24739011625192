import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'monedaDecimales'
})
export class MonedaDecimalesPipe implements PipeTransform {

  transform(value: number, moneda: string = "", decimales: number = 0): string {
    let x = parseFloat(value.toString()).toFixed(decimales);
    if (moneda === "Dólares") {
      return "$ " + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return "C$ " + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }

}
