import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fecha'
})
export class FechaPipe implements PipeTransform {

  transform(value: string): string {

    const shortMonthNames = [
      "Ene", "Feb", "Mar",
      "Abr", "May", "Jun", "Jul",
      "Ago", "Set", "Oct",
      "Nov", "Dic"];

    if (value == "" || value == undefined || value == null) {
      return "No indica"
    } else {
      const date = new Date(value);
      return `${date.getDate()} ${shortMonthNames[date.getMonth()]} ${date.getFullYear()}`;
    }
  }

}
