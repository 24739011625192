import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-card',
  templateUrl: './admin-card.component.html',
  styleUrls: ['./admin-card.component.scss'],
})
export class AdminCardComponent implements OnInit {

  @Input() color = "primary"
  @Input() label = "";
  @Input() icon:any = "add";
  colorBorder = "border-left: solid var(--ion-color-primary) 2px;"

  constructor() {

  }

  ngOnInit() {
    this.colorBorder = `border-left: solid var(--ion-color-${this.color}) 2px;`
  }

}
