import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ServicePaths {

  VERSION_APP: string = "2.2.2"


  //private IP_SERVER_PORT     : string = "localhost:1011"
  //private IP_SERVER_PORT     : string = "10.10.30.90:1011"
  private IP_SERVER_PORT     : string = "184.168.31.165:1011"
  public BACKEND_PATH        : string = `http://${this.IP_SERVER_PORT}/api/`;
  public BACKEND_PATH_IMAGES : string = `http://${this.IP_SERVER_PORT}/imagenes/`;

  constructor() { }
}



//  export const IP_SERVER_PORT:string = "184.168.31.165:1011"
//  export const IP_SERVER_PORT: string      = "localhost:1011"