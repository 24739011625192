import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimales'
})
export class DecimalesPipe implements PipeTransform {

  transform(value: any, decimales:number  = 0, prefijo:string = ''): unknown { 
    let x = parseFloat(value).toFixed(decimales);
    const numero = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); 
    if (numero == "NaN") {
      return prefijo + 0;
    }else{
    return prefijo + numero;
    } 
  }

}
