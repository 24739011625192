import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-firma',
  templateUrl: './firma.component.html',
  styleUrls: ['./firma.component.scss'],
})
export class FirmaComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
