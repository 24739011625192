import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ModelEstadoEquipo } from 'src/app/model/models/model_reporte';
import arraySeleccion from 'src/app/model/models/model_seleccion';

@Component({
  selector: 'app-estado-equipo',
  templateUrl: './estado-equipo.component.html',
  styleUrls: ['./estado-equipo.component.scss'],
})
export class EstadoEquipoComponent implements OnInit {

  array:ModelEstadoEquipo[] = arraySeleccion


  @Output() selected = new EventEmitter<ModelEstadoEquipo[]>();

  constructor() { }

  ngOnInit() {
    this.selected.emit(this.array)
  }

  change(index:number){
    if (!this.array[index].check) {
      this.array[index].estado = ""
    }else{
      this.array[index].estado = "regular"
    }
    this.selected.emit(this.array)
  }

  segment(){
    this.selected.emit(this.array)
  }

}
