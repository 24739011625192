import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class ServiceStorage {

  private _storage: Storage;

  constructor(private storage: Storage) {
    this.init();
  }

  async init() {
    this._storage = await this.storage.create();
  }

  public async set(value: any, key: string) {
     await this._storage?.set(key, value);
  }

  public async get(key: string, or: any = null): Promise<any> {
    const result = await this._storage?.get(key) ?? or
    return result
  }

  public  async clear(key: string){
    await this._storage?.remove(key);
  }

  public setToken(token: string) {
    this._storage?.set("x-token", token);
  }

  public async getToken(): Promise<string> {
    const result = await this._storage?.get("x-token") ?? ""
    return result
  }

  public setDarkMode(isDark: boolean) {
    this._storage?.set('isDark', isDark);
  }

  public async getDarkMode(): Promise<boolean> {
    const result = await this._storage?.get('isDark') ?? false
    return result
  }

  public async closeSession(){
    await this._storage?.clear();
  }

  public async setLastUSer(user:string){
    await this._storage?.set('last-user', user);
  }

  public async getLastUSer(): Promise<string>{
    const result = await this._storage?.get('last-user') ?? ""
    return result
  }
}
