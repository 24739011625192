
export class Model_Cotizardor_Ajustes{
    _id                  : string;
    costo_hora_normal    :  string;
    costo_hora_extra     :  string;
    costo_hora_doble     :  string;
    costo_hora_especial  :  string;
    cargas_sociales      :  string;
    viaticos             :  viaticos;
    kilometraje          :  kilometraje;
    precio_trabajo       :  Array<precio_trabajo>;


    constructor(
        _id?                  :  string,
        costo_hora_normal?    :  string,
        costo_hora_extra?     :  string,
        costo_hora_doble?     :  string,
        costo_hora_especial?  :  string,
        cargas_sociales?      :  string,
        viaticos?             :  viaticos,
        kilometraje?          :  kilometraje,
        precio_trabajo?       :  Array<precio_trabajo>,
    ){ 
        this._id                 = _id,
        this.costo_hora_normal   = "",
        this.costo_hora_extra    = "",
        this.costo_hora_doble    = "",
        this.costo_hora_especial = "",
        this.cargas_sociales     = "",
        this.viaticos            =  {
            zona_1 : { 
                desayuno  : "",
                almuerzo  : "",
                cena      : "",
                hospedaje : "",
            },
            zona_2 : {
                desayuno  : "",
                almuerzo  : "",
                cena      : "",
                hospedaje : "",
            },
            zona_3 : {
                desayuno  : "",
                almuerzo  : "",
                cena      : "",
                hospedaje : "",
            }
        },
        this.kilometraje       =  {
            carro_pequeno : {
                precio       : "",
                depreciacion : "",
            },
            carro_mediano : {
                precio       : "",
                depreciacion : "",
            },
            furgon  : {
                precio       : "",
                depreciacion : "",
            },
            moto : {
                precio       : "",
                depreciacion : "",
            }
        },
        this.precio_trabajo   = [        
        ]
    }
}

interface viaticos{
    zona_1 : {
        desayuno  : string,
        almuerzo  : string,
        cena      : string,
        hospedaje : string,        
    },
    zona_2 : {
        desayuno  : string,
        almuerzo  : string,
        cena      : string,
        hospedaje : string,
    },
    zona_3 : {
        desayuno  : string,
        almuerzo  : string,
        cena      : string,
        hospedaje : string,
    }
}

interface kilometraje{
    carro_pequeno : {
        precio       : string,
        depreciacion : string,
    },
    carro_mediano : {
        precio       : string,
        depreciacion : string,
    },
    furgon  : {
        precio       : string,
        depreciacion : string,
    },
    moto : {
        precio       : string,
        depreciacion : string,
    }
}

export interface precio_trabajo {
    equipo    : string,
    mant_cal  : number,
    cal       : number,
    mant      : number,
    verif     : number,
    emer      : number,
}