import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'telefono'
})
export class TelefonoPipe implements PipeTransform {

  transform(value: string): string {
    const parte1 = value.slice(0, 3);
    const parte2 = value.slice(4, 7);
    return `${parte1}-${parte2}`
  }

}
