import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Camera, CameraResultType, ImageOptions } from '@capacitor/camera';
import { ServiceText } from 'src/app/services/text.service';

@Component({
  selector: 'app-load-imagenes',
  templateUrl: './load-imagenes.component.html',
  styleUrls: ['./load-imagenes.component.scss'],
})
export class LoadImagenesComponent implements OnInit {

  @Output() loaded = new EventEmitter<any>();
  @Output() movil = new EventEmitter<any>();
  @Input() isOnline:boolean = false;

  constructor(
    public platform:Platform,
    private txtServ: ServiceText
  ) { }

  ngOnInit() {}

  async loadImagePhone(){

    const options:ImageOptions = {
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Base64 ,
      correctOrientation:true,
      promptLabelPhoto:"Galería",
      promptLabelCancel:"Cancelar",
      promptLabelPicture:"Cámara",
      saveToGallery: false,
      width : 500,
      height: 500
    }
      const image = await Camera.getPhoto(options);
      this.movil.emit('data:image/jpeg;base64,' + image.base64String);
  }  


  async loadFile(event:any){
    const file = <File>event.target.files[0];
    let formData = new FormData();
    formData.append('file',file,"testeo.jpeg");
    this.loaded.emit(formData);
  }


}
