import { Injectable } from '@angular/core';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { AlertController } from '@ionic/angular';
import { ServiceStorage } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceBiometric {

  constructor(
    private faio        : FingerprintAIO,
    private storage     : ServiceStorage,
    private alertCtrl   : AlertController,
  ) { }





  async biometricIsAvailable():Promise<boolean> {
    return new Promise( (resolve, reject) => {
      this.faio.isAvailable().then((result: any) => {
        resolve((result == 'face' || result == 'biometric' || result === 'finger') ? true : false);
      }).catch( (err) => {
        reject(false)
      });
    });
  }




   async autenticate(): Promise<boolean>{
    return new Promise( (resolve,reject) => {
      this.faio.show({
        cancelButtonTitle: 'Cancelar',
          description: "Autenticación Biométrica",
          disableBackup: true,
          title: 'Autenticación',
          fallbackButtonTitle: 'Atrás',
          subtitle: 'Autenticación'
      }).then((result: any) => {
        if (result == 'Success') {
          resolve(true);
        } else {
          resolve(true);
        }
      })
      .catch(async (error: any) => {
        resolve(false);
        const alert = await this.alertCtrl.create({
           header: 'Atención',
           message: 'Ocurrio un error, al autenticar',
           buttons: ['OK'],
        });
        await alert.present();
      });
    })
      

  }


  setBio(isActive: boolean){
    this.storage.set(isActive, 'biometric');
  }



  async getBio():Promise<boolean>{
    const result = await this.storage.get('biometric')
     return (result === true) ? true : false;
  }


}
