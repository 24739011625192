import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pesas-done',
  templateUrl: './pesas-done.component.html',
  styleUrls: ['./pesas-done.component.scss'],
})
export class PesasDoneComponent implements OnInit {

  @Input() pesas: any[] = [];

  constructor() { }

  ngOnInit() {}

}
