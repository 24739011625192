import { Interfase_Trabajos } from "../interfaces/interfase_cotizador";

export class Model_Cotizador {
    _id                    : string;
    titulo                 : string;
    ajuste_id              : string;
    por                    : string;
    codigo_cliente         : string;
    consecutivo            : number;
    dias_trabajo           : number;
    cliente                : string;
    cantidad_trabajadores  : number;
    habitaciones           : number;
    viaticos               : Array<string>;
    provincia              : string;
    canton                 : string;
    distrito               : string;
    tipo_vehiculo          : string;
    horas_normales         : number;
    horas_extra            : number;
    horas_dobles           : number;
    horas_especiales       : number;
    tipo_cambio            : number;
    diasVehiculo           : number;
    total                  : number;
    zona                   : string;
    createdAt              : string;
    distancia              : number;
    extra1                 : string;
    extra2                 : string;
    extra3                 : string;
    extra4                 : string;
    extra5                 : string;
    trabajo                : Array<Interfase_Trabajos>;
    array1                 : Array<any>;
    array2                 : Array<any>;


    constructor(
        _id?                    : string,
        titulo?                 : string,
        ajuste_id?              : string,
        por?                    : string,       
        codigo_cliente?         : string,
        consecutivo?            : string,
        cliente?                : string,
        dias_trabajo?           : number,
        cantidad_trabajadores?  : number,
        habitaciones?           : number,
        viaticos?               : Array<string>,
        provincia?              : string,
        canton?                 : string,
        distrito?               : string,
        tipo_vehiculo?          : string,
        diasVehiculo?           : number,
        horas_normales?         : string,
        horas_extra?            : string,
        horas_dobles?           : string,
        horas_especiales?       : string,
        tipo_cambio?            : number,
        createdAt?              : string,
        total?                  : number,
        extra1?                 : string,
        extra2?                 : string,
        extra3?                 : string,
        extra4?                 : string,
        extra5?                 : string,
        trabajo?                : Array<Interfase_Trabajos>,
        array1?                 : Array<any>,
        array2?                 : Array<any>,  
    ) {
        this.codigo_cliente         = "";
        this.titulo                 = "";
        this.por                    = "";
        this.ajuste_id              = "";
        this.dias_trabajo           = 1;
        this.consecutivo            = 0;
        this.cliente                = "";
        this.cantidad_trabajadores  = 1;
        this.habitaciones           = 0;
        this.viaticos               = [];
        this.provincia              = "";
        this.canton                 = "";
        this.distrito               = "";
        this.tipo_vehiculo          = "";
        this.diasVehiculo           = 0;
        this.horas_normales         = 0;
        this.horas_extra            = 0;
        this.horas_dobles           = 0;
        this.horas_especiales       = 0;
        this.tipo_cambio            = 0;
        this.total                  = 0;
        this.createdAt              = "";
        this.zona                   = "";
        this.distancia              = 0 ;
        this.extra1                 = "";
        this.extra2                 = "";
        this.extra3                 = "";
        this.extra4                 = "";
        this.extra5                 = "";
        this.trabajo                = [];
        this.array1                 = [];
        this.array2                 = [];
    }

}