import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fechaDia'
})
export class FechaDiaPipe implements PipeTransform {

  transform(today: Date): unknown {

    const dias = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sabado"
    ];

    const mes = [
      "Enero", "Febrero", "Marzo",
      "Abril", "Mayo", "Junio", "Julio",
      "Agosto", "Setiembre", "Octubre",
      "Noviembre", "Diciembre"];



    return `${dias[today.getDay()]} ${today.getDate()} de ${mes[today.getMonth()]}`
  }

}
