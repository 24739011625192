import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { ModelPesa } from 'src/app/model/interfaces/interface_pesa';
import { ServiceHttp } from 'src/app/services/http.service';

@Component({
  selector: 'app-pesas',
  templateUrl: './pesas.component.html',
  styleUrls: ['./pesas.component.scss'],
})
export class PesasComponent implements OnInit {

  //@Input() 
  pesas:ModelPesa[] = []
  @Output() checkit = new EventEmitter<ModelPesa[]>();

  isOpen: boolean = false
  counter:number = 0

  constructor(
    private servHttp: ServiceHttp,
    private alertCtrl:AlertController
  ) {

   }

  ngOnInit(
   
  ) {
    this.getPesas();
  }

  checkIt(){
   let checked:ModelPesa[] = [];
   this.counter = 0
   for (let i = 0; i < this.pesas.length; i++) {
    const ele = this.pesas[i];
    if(ele.check){
      if (ele.label.includes('D-')) {
        this.counter = this.counter + 1     
      }
      checked.push(ele)
    }    
   }
   this.checkit.emit(checked)
  }

  async getPesas(){
    const result = await this.servHttp.get("get-pesas");
    if (result.did) {
      this.pesas = result.res.pesas
    } else {
      const alert = await this.alertCtrl.create({
        header: 'Atención',
        message: result.msg,
        buttons: ['OK']
      });
      await alert.present();
    }
  }
  

  openContent(){
    this.isOpen = !this.isOpen;
  }

}
