import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PesasManipular } from 'src/app/model/models/model_ot';

@Component({
  selector: 'app-tabla-pesas',
  templateUrl: './tabla-pesas.component.html',
  styleUrls: ['./tabla-pesas.component.scss'],
})
export class TablaPesasComponent implements OnInit {


  med = 5;

  @Input() pesas:PesasManipular = {
    k05:"",
    k10:"",
    k20:"",
    k200:"",
    k500:"",
    k1000:"",
    sustitucion:""
  }

  @Input() disable:boolean = false;

  @Output() onChage = new EventEmitter<PesasManipular>();

  constructor() { }

  ngOnInit() {}

  change(){
    this.onChage.next(this.pesas);
  }

  number(num: string):number {
    return Number(num);
  }

}
