import { Component, Input, OnInit } from '@angular/core';
import { ModelCheckList } from 'src/app/model/interfaces/interface_checklist';


@Component({
  selector: 'app-head-checklist',
  templateUrl: './head-checklist.component.html',
  styleUrls: ['./head-checklist.component.scss'],
})
export class HeadChecklistComponent implements OnInit {

  @Input() data:any = {

  };

  constructor() { }

  ngOnInit() { }

}
