import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Interfase_Trabajos } from 'src/app/model/interfaces/interfase_cotizador';
import { Model_Cotizardor_Ajustes } from 'src/app/model/models/model_cotizador_ajustes';
import { ModalNotasPage } from 'src/app/pages/side-menu/pages/cotizaciones/cotizador/modal-notas/modal-notas.page';
import { ServiceText } from 'src/app/services/text.service';

@Component({
  selector: 'app-cotizador-table-equipos',
  templateUrl: './cotizador-table-equipos.component.html',
  styleUrls: ['./cotizador-table-equipos.component.scss'],
})
export class CotizadorTableEquiposComponent implements OnInit {

  @Input() trabajos:Interfase_Trabajos[] = [
    {
      equipo : '',
      servicio : '',
      cantidad : 1,
      costo : 0,
      descuento : 0,
      total : 0,
      notas : '',
    }
  ];

  @Input() ajustes:Model_Cotizardor_Ajustes = new Model_Cotizardor_Ajustes();

  @Input() disabled:boolean = false;

  @Output() trabajosChange = new EventEmitter<Interfase_Trabajos[]>();

  popoverOptions = {
    cssClass: 'my-popover'
  };


  tipoServicio = [
    {
      serv:"Mantenimiento y Calibración",
      key:"mant_cal"
    },
    {
      serv:"Mantenimiento",
      key:"mant"
    },
    {
      serv:"Calibración",
      key:"cal"
    },
    {
      serv:"Verificación",
      key:"verif"
    },
    {
      serv:"Emergencia",
      key:"emer"
    }
  ]


  totalEquipos:number = 0;



  constructor(
    private txtServ     : ServiceText,
    private modalCtrl   : ModalController,
  ) { }

  ngOnInit() {

    if (this.trabajos.length == 0) {
      this.trabajos.push({
        servicio    : '',
        equipo      : '',
        cantidad    : 1,
        costo       : 0,
        descuento   : 0,
        total       : 0,
        notas       : '',
      }); 
      this.trabajosChange.emit(this.trabajos);
    }
  }


  comprobratelinesEmpty(){
    this.totalizar();
    if (this.trabajos[this.trabajos.length - 1].servicio != "" && this.trabajos[this.trabajos.length - 1].equipo != "") {
      this.trabajos.push({
        servicio    : '',
        equipo      : '',
        cantidad    : 1,
        costo       : 0,
        descuento   : 0,
        total       : 0,
        notas       : '',
      }); 
      this.trabajosChange.emit(this.trabajos);
    }
  }

  deleteLine(index:number){
    this.trabajos.splice(index, 1);
    this.trabajosChange.emit(this.trabajos);
  }




  public totalizar(){
    this.totalEquipos = 0;

    for (let i = 0; i < this.trabajos.length; i++) {
      if (this.trabajos[i].servicio != "" && this.trabajos[i].servicio != "") {
        const element = this.trabajos[i];
        this.trabajos[i].costo = this.getPrice(element.equipo,element.servicio);
        const servicios =this.trabajos[i].costo * this.trabajos[i].cantidad
        const descuento = servicios * (this.trabajos[i].descuento / 100);
        this.trabajos[i].total = servicios - descuento;

        this.totalEquipos = this.totalEquipos + this.trabajos[i].cantidad;
      }
    }
    this.trabajosChange.emit(this.trabajos);
  }



  getPrice(equipo:string,servicio:string):number{
    let precio = 0;
    for (let i = 0; i < this.ajustes.precio_trabajo.length; i++) {
      const element = this.ajustes.precio_trabajo[i];
      if (equipo == element.equipo) {
      precio = this.txtServ.StringToNumero(element[servicio].toString());
      console.log('precio',precio,"----> entro");
      }     
    }
    return precio; 
  }


  async editarNota(index: number){
    const modal = await this.modalCtrl.create({
      component: ModalNotasPage,
      componentProps: {
        disabled : this.disabled,
        nota: this.trabajos[index].notas
      }
    });
    modal.present();
    modal.onDidDismiss().then( async (data) => {
      if (data.data != undefined) {
        this.trabajos[index].notas = data.data.nota;
        this.trabajosChange.emit(this.trabajos);
      }
    });
  }



}
