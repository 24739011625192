import { Component, Input, OnInit } from '@angular/core';
import { ModelOT } from 'src/app/model/models/model_ot';
import { ServiceLoginUser } from 'src/app/services/login-user.service';

@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss'],
})
export class ResumeComponent implements OnInit {

  @Input() ot:ModelOT = new ModelOT()

  constructor(
    public logServ: ServiceLoginUser

  ) { 
  }

  ngOnInit() {}

}
