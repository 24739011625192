import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-notas',
  templateUrl: './modal-notas.page.html',
  styleUrls: ['./modal-notas.page.scss'],
})
export class ModalNotasPage implements OnInit {

  @Input() nota: string = '';
  @Input() disabled: boolean = false;

  constructor(
    public modalCtrl: ModalController,
  ) { }

  ngOnInit() {
  }


  save(){
    this.modalCtrl.dismiss({
      nota: this.nota
    });
  }

}
