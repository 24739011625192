import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fechaHora'
})
export class FechaHoraPipe implements PipeTransform {

  transform(value: string): string {
    const shortMonthNames = [
      "Ene", "Feb", "Mar",
      "Abr", "May", "Jun", "Jul",
      "Ago", "Set", "Oct",
      "Nov", "Dic"];

    if (value == "" || value == undefined || value == null) {
      return "No indica"
    } else {
      const date = new Date(value);
      return `${date.getDate()} ${shortMonthNames[date.getMonth()]} ${date.getFullYear()} - ${this.getHour(value)}`;
    }
  }

  getHour(dat: string) {
    let date = new Date(dat);
    var hours = date.getHours();
    var minutes: any = date.getMinutes();
    var ampm = hours >= 12 ? 'pm': 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

}
