import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModelChecklistComponent } from 'src/app/model/interfaces/interfase_checlist';


@Component({
  selector: 'app-documentos-checklist',
  templateUrl: './documentos-checklist.component.html',
  styleUrls: ['./documentos-checklist.component.scss'],
})
export class  DocumentosChecklistComponent implements OnInit {

  @Output() onDid = new EventEmitter<any>();

  @Input() btnDisabled:boolean = true;



  equipo_auxiliar:ModelChecklistComponent[] = [
    {
      item1:{
        label:"Termohigrómetro",
        value:false
      },item2:{
        label:"Etiquetadora y Cinta",
        value:false
      }
    },
    {
      item1:{
        label:"Computadora y Cargador",
        value:false
      },item2:{
        label:"Viáticos",
        value:false
      }
    },
    {
      item1:{
        label:"Facturas",
        value:false
      },item2:{
        label:"Kit de Tempratura",
        value:false
      }
    },
    {
      item1:{
        label:"Buffer (PH)",
        value:false
      },item2:{
        label:"Buffer (Conductividad)",
        value:false
      }
    },
    {
      item1:{
        label:"Vasos Precipitado",
        value:false
      },item2:{
        label:"Guantes",
        value:false
      }
    },
  ]

  documentos:ModelChecklistComponent[] = [
    {
      item1:{
        label:"P6-VI-F3",
        value:false
      },item2:{
        label:"Informe de Revisiones",
        value:false
      }
    },
    {
      item1:{
        label:"Facturas",
        value:false
      },item2:{
        label:"Colillas (INS( y Cédula",
        value:false
      }
    },
  ]

  vehiculo:ModelChecklistComponent[] = [
    {
      item1:{
        label:"Gata",
        value:false
      },item2:{
        label:"Triángulos",
        value:false
      }
    },
    {
      item1:{
        label:"Extintor",
        value:false
      },item2:{
        label:"Llantas",
        value:false
      }
    },
    {
      item1:{
        label:"Luces",
        value:false
      },item2:{
        label:"Gasolina",
        value:false
      }
    },
    {
      item1:{
        label:"Aceite",
        value:false
      },item2:{
        label:"Agua",
        value:false
      }
    },
    {
      item1:{
        label:"Documentos",
        value:false
      },item2:{
        label:"Aseguramiento de Pesas",
        value:false
      }
    },
  ]

  constructor(

  ) { }

  ngOnInit() {}
  





  checekit(){

    let check:any = [
      {
        "cathegoria":"Equipos Auxiliares",
        "check": this. equipo_auxiliar
      },
      {
        "cathegoria":"Documentos",
        "check": this.documentos
      },
      {
        "cathegoria":"Vehículo",
        "check": this.vehiculo
      }
    ];

    this.onDid.emit(check);
  }

}
