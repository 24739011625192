import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'acompanantes'
})
export class AcompanantesPipe implements PipeTransform {

  transform(array: Array<string>): string {


    if (array == null || array == undefined) {
      return "----"
    } else {
      let value = ""
      for (let i = 0; i < array.length; i++) {
        if (i < (array.length - 1)) {
          value = value + " " + array[i] + ","
        } else {
          value = value + " " + array[i]
        }
      }
      return value;
    }


  }

}
