import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AlertController, LoadingController, NavController } from '@ionic/angular';
import { ServiceLoginUser } from './login-user.service';
import { ModelRes } from '../model/interfaces/interface_res';
import { ServicePaths } from './paths.service';
import { ServiceBiometric } from './biometric.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceHttp {






  constructor(
    private http       : HttpClient,
    private loadCtrl   : LoadingController,
    private alertCtrl  : AlertController,
    private navCtrl    : NavController,
    private userServ   : ServiceLoginUser,
    private paths      : ServicePaths,
    private bioServ    : ServiceBiometric
  ) { }







  async post(path: string, body: any): Promise<ModelRes> {
    const loader = await this.loadCtrl.create({ message: "Conectando" });
    loader.present();
    const headers = await this.getHeaders()
    return new Promise((resolve, reject) => {
      this.http.post(this.paths.BACKEND_PATH + path, body, headers).subscribe((response: ModelRes) => {
        loader.dismiss();
        resolve(response)
      }, async (error) => {
        console.log('error', error);
        const alert = await this.alertCtrl.create({
          header: 'Atención',
          message: 'Ocurrió un error, intenta de nuevo.',
          buttons: ['OK'],
        });
        loader.dismiss();
        alert.present();
        if (!error.error.token) {
          this.bioServ.setBio(false);
          this.navCtrl.navigateRoot('login');
        }
        reject({ did: false, msg: "Ocurrio un error, intenta de nuevo", res: {} });
      });
    });
  }










  async get(path: string): Promise<ModelRes> {
    const loader = await this.loadCtrl.create({ message: "Conectando" });
    loader.present();
    const headers = await this.getHeaders()
    return new Promise((resolve, reject) => {
      this.http.get(this.paths.BACKEND_PATH + path, headers).subscribe((response: ModelRes) => {
        loader.dismiss();
        resolve(response);
      }, async (error) => {
        console.log('error', error);
        loader.dismiss();
        const alert = await this.alertCtrl.create({
          header: 'Atención',
          message: 'Ocurrió un error, intenta de nuevo.',
          buttons: ['OK'],
        });
        alert.present();
        if (!error.error.token) {
          this.bioServ.setBio(false);
          this.navCtrl.navigateRoot('login');
        }
        reject({ did: false, msg: "Ocurrio un error, intenta de nuevo", res: {} });
      })
    });
  }










  async getPDF(path: string): Promise<ModelRes> {
    const loader = await this.loadCtrl.create({ message: "Descargando" });
    loader.present();
    const headers = await this.getHeadersPDF()
    return new Promise((resolve, reject) => {
      this.http.get(this.paths.BACKEND_PATH + path, headers).subscribe((response: ModelRes) => {
        loader.dismiss();
        resolve(response);
      }, async (error) => {
        console.log('error', error);
        const alert = await this.alertCtrl.create({
          header: 'Atención',
          message: 'Ocurrió un error, intenta de nuevo.',
          buttons: ['OK'],
        });
        loader.dismiss();
        alert.present();
        if (!error.error.token) {
          this.bioServ.setBio(false);
          this.navCtrl.navigateRoot('login');
        }
        reject({ did: false, msg: "Ocurrio un error, intenta de nuevo", res: {} });
      })
    });
  }











  async postimage(formData: FormData, path: string): Promise<ModelRes> {
    const loader = await this.loadCtrl.create({ message: "Subiendo" });
    loader.present();
    const headers = await this.getHeaders2()
    return new Promise((resolve, reject) => {
      this.http.post(this.paths.BACKEND_PATH + path, formData, headers).subscribe((response: ModelRes) => {
        loader.dismiss();
        resolve(response);
      }, async (error) => {
        console.log('error', error);
        loader.dismiss();
        const alert = await this.alertCtrl.create({
          header: 'Atención',
          message: 'Ocurrió un error, intenta de nuevo.',
          buttons: ['OK'],
        });
        alert.present();
        if (!error.error.token) {
          this.bioServ.setBio(false);
          this.navCtrl.navigateRoot('login');
        }
        reject({ did: false, msg: "Ocurrio un error, intenta de nuevo", res: {} });
      });
    });
  }








  async getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type' : 'application/json',
        'Accept'       : 'application/json',
        'x-token'      : this.userServ.userData.token,
        'x-uuid'       : await this.userServ.getUUID()
      })
    }
    return httpOptions
  }







  async getHeaders2() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept'       : 'application/json',
        'x-token'      : this.userServ.userData.token,
        'x-uuid'       : await this.userServ.getUUID()
      })
    }
    return httpOptions
  }









  async getHeadersPDF() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type' : 'application/pdf',
        'Accept'       : 'application/pdf',
        'x-token'      : this.userServ.userData.token,
        'x-uuid'       : await this.userServ.getUUID()
      })
    }
    return httpOptions
  }

















  async getFullPath(path: string): Promise<number> {
    const loader = await this.loadCtrl.create({ message: "Conectando" });
    loader.present();
    return new Promise((resolve, reject) => {
      this.http.get(path, {
        headers: new HttpHeaders({
         // 'Content-Type': 'application/json',
        })
      }).subscribe((response:any ) => {
        loader.dismiss();
        const suma:number = parseFloat(((parseFloat(response.compra) + parseFloat(response.venta)) / 2 ).toFixed(2));
        resolve(suma);
      }, async (error) => {
        loader.dismiss();
        const alert = await this.alertCtrl.create({
          header: 'Atención',
          message: 'Ocurrió un error, intenta de nuevo.',
          buttons: ['OK'],
        });
        alert.present();
        if (!error.error.token) {
          this.bioServ.setBio(false);
          this.navCtrl.navigateRoot('login');
        }
        reject({ did: false, msg: "Ocurrio un error, intenta de nuevo", res: {} });
      })
    });
  }


}
