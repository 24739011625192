import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EquiposManipular } from 'src/app/model/models/model_ot';

@Component({
  selector: 'app-tabla-equipos',
  templateUrl: './tabla-equipos.component.html',
  styleUrls: ['./tabla-equipos.component.scss'],
})
export class TablaEquiposComponent implements OnInit {

  @Input() equipos:EquiposManipular = {
    montacargas: false,
    carretilla:false,
    ascensor: false,
    montacargasAzocar:false
  }

  @Input() disabled:boolean = false;

  @Output() onChange = new EventEmitter<EquiposManipular>();

  constructor() { }

  ngOnInit() {

  }

  send(){
    this.onChange.next(this.equipos);
  }

  montacargas(){
    if (this.disabled) return
    this.equipos.montacargas = !this.equipos.montacargas;
    this.send();
  }


  carretilla(){
    if (this.disabled) return
    this.equipos.carretilla = !this.equipos.carretilla;
    this.send();
  }


  ascensor(){
    if (this.disabled) return
    this.equipos.ascensor = !this.equipos.ascensor;
    this.send();
  }


  montaAzocar(){
    if (this.disabled) return
    this.equipos.montacargasAzocar = !this.equipos.montacargasAzocar;
    this.send();
  }

}
