import { Pipe, PipeTransform } from '@angular/core';
import { ModelSSTView } from '../model/models/model_ot';

@Pipe({
  name: 'asignado'
})
export class AsignadoPipe implements PipeTransform {

  transform(value: ModelSSTView): string {
    if (!value.aprobaciones.aprobado) return 'Pendiente Aprobar'
    if (!value.asignacion.asignado) return 'Sin Asignar'
    if (value.checklist.hecho) return 'En Proceso'
    if (value.aprobaciones.aprobado) return 'Asignado'
  }

}
