import { Component, Input, OnInit } from '@angular/core';
import { Model_Cotizador } from 'src/app/model/models/model_cotizador';

@Component({
  selector: 'app-cotizador-table-totales',
  templateUrl: './cotizador-table-totales.component.html',
  styleUrls: ['./cotizador-table-totales.component.scss'],
})
export class CotizadorTableTotalesComponent implements OnInit {

  @Input() cotizacion:Model_Cotizador = new Model_Cotizador();
  @Input() total_viaticos:number = 0;
  @Input() total_kilometraje:number = 0;
  @Input() total_personal:number = 0;
  @Input() total_equipos:number = 0;
  @Input() tc:number = 0;

  constructor() { }

  ngOnInit() {}

}
