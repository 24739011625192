import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FechaPipe } from './fecha.pipe';
import { AcompanantesPipe } from './acompanantes.pipe';
import { ActivoPipe } from './activo.pipe';
import { AsignadoPipe } from './asignado.pipe';
import { FechaHoraPipe } from './fecha-hora.pipe';
import { TelefonoPipe } from './telefono.pipe';
import { MonedaPipe } from './moneda.pipe';
import { FechaDiaPipe } from './fecha-dia.pipe';
import { ColorChecklistPipe } from './color-checklist.pipe';
import { FirmaPipe } from './firma.pipe';
import { DecimalesPipe } from './decimales.pipe';
import { MonedaDecimalesPipe } from './moneda-decimales.pipe';
import { SignoPorcientoPipe } from './signo-porciento.pipe';





@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    FechaPipe,
    AcompanantesPipe,
    ActivoPipe,
    AsignadoPipe,
    FechaHoraPipe,
    TelefonoPipe,
    MonedaPipe,
    FechaDiaPipe,
    ColorChecklistPipe,
    FirmaPipe,
    DecimalesPipe,
    MonedaDecimalesPipe,
    SignoPorcientoPipe
  ],
  exports: [
    FechaPipe,
    AcompanantesPipe,
    ActivoPipe,
    AsignadoPipe,
    FechaPipe,
    FechaHoraPipe,
    TelefonoPipe,
    MonedaPipe,
    FechaDiaPipe,
    ColorChecklistPipe,
    FirmaPipe,
    DecimalesPipe,
    MonedaDecimalesPipe,
    SignoPorcientoPipe
  ]
})
export class PipesModule { }
