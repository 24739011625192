import { Component } from '@angular/core';
import { ServiceStorage } from './services/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private servStorage  : ServiceStorage,
    ) { 
    }
}
