import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Interface_Pesos} from 'src/app/model/models/model_ot';

@Component({
  selector: 'app-pesas-bodega',
  templateUrl: './pesas-bodega.component.html',
  styleUrls: ['./pesas-bodega.component.scss'],
})
export class PesasBodegaComponent implements OnInit {

  @Input() pesos:Interface_Pesos = {
    a01      : '',
    ae2     : '',
    aJuego  : '',
    ab      : '',
    b       : '',
    bJuego  : '',
    c       : '',
    cf      : '',
    df      : '',
    dnm1    : '',
  }

  @Input() disable:boolean = false;

  @Output() onChange = new EventEmitter<Interface_Pesos>();

  constructor(
  ) { }

  ngOnInit() {}

  async save(){
      this.onChange.next(this.pesos);
  }

  number(num: string):number {
    return Number(num);
  }



}
