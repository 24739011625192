import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { ServiceLoginUser } from './login-user.service';


@Injectable({
  providedIn: 'root'
})
export class ServiceAuthGuardService {

  constructor(
    private router: Router,
    private logServ: ServiceLoginUser
  ) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    let authInfo = {
      authenticated: this.logServ.userIsLogin,
    };
    if (!authInfo.authenticated) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }

}
