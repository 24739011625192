import { ESTADO } from "../enums/enums";
import { ModelSST } from "./model_sst";

export class ModelOT {

    _id          : string
    consecutivo  : string;
    version      : number;
    sst          : ModelSST;
    asignacion   : Asignacion;
    aprobaciones : Aprobaciones;
    checklist    : string;
    reportes     : Array<Reporte>
    gps          : GPS
    historial    : Historial
    firma        : Firma
    activo       : boolean
    estado       : ESTADO
    offlineEstado: string
    bodega          : Bodega


    constructor(
        _id?          : string,
        consecutivo?  : string, 
        version?      : number, 
        sst?          : ModelSST, 
        asignacion?   : Asignacion,
        aprobaciones? : Aprobaciones,
        checklist?    : string,
        reportes?     : Array<Reporte>,
        gps?          : GPS,
        historial?    : Historial,
        firma?        : Firma,
        activo?       : boolean,
        estado?       : ESTADO,
        offlineEstado? : string,
        bodega?          : Bodega
        ){
            this._id = _id
            this.consecutivo   = "";
            this.version       = 0;
            this.sst           = {
                solicitante     : "",
                cliente         : "",
                cliente_id      : "",
                proforma        : "",
                direccion       : "",
                contacto        : "",
                email           : "",
                telefono        : "",
                celular         : "",
                cantidadEquipos : "",
                servicio        : [],
                descripcion     : "",
                notas           : "",
                duracion        : "",
                equipo          : [],
                periocidad      : "",
                precio          : "",
                moneda          : "Córdobas",
                fecha           : "",
                fechaProx       : "",
                notaIncompleto  : "",
                pesaMantenimiento  : "",
                pesaCalibracion  : "",
            };
            this.asignacion    = {
                fechaInicio       : "",
                fechaFin          : "",
                tecnico           : "",
                vehiculo          : "",
                asistentes        : [],
                asignado          : false ,
                fecha             : "",
                por               : "",
                pesaMantenimiento : {
                    k05         : "",
                    k10         : "",
                    k20         : "",
                    k200        : "",
                    k500        : "",
                    k1000       : "",
                    sustitucion : ""
                },
                pesaCalibracion   : "",
                notas             : "", 
                pesasManipular    : {
                    k05         : "",
                    k10         : "",
                    k20         : "",
                    k200        : "",
                    k500        : "",
                    k1000       : "",
                    sustitucion : "",
                },
                equiposManipular  : {
                    montacargas       : false,
                    carretilla        : false,
                    ascensor          : false,
                    montacargasAzocar : false,
                },
                pesos: {
                    a01      : "",
                    ae2     : "",
                    aJuego  : "",
                    ab      : "",
                    b       : "",
                    bJuego  : "",
                    c       : "",
                    cf      : "",
                    df      : "",
                    dnm1   : "",
                }
            };
            this.aprobaciones  = {
                aprobado : false,
                fecha    : "",
                por      : "",
                notas    : "",
                horaLlegada: "",
            };
            this.checklist     = checklist;
            this.reportes      = reportes;
            this.gps           = gps;
            this.historial     = historial;
            this.firma         = {
                nombre: '',
                cedula: '',
                firma: '',
                fecha: ''
            };
            this.bodega        = bodega;
            this.activo        = activo;
            this.estado        = estado;
            this.offlineEstado = offlineEstado
    }
}




interface Bodega {

    fecha      : string,
    por        : string,
    notas      : string,
    bodega_id  : string,
    estado     : boolean

}



//ASIGNACION
interface Asignacion {
    fechaInicio       : string
    fechaFin          : string
    tecnico           : string
    vehiculo          : string
    asistentes        : Array<string>
    asignado          : boolean ,
    fecha             : string
    por               : string
    pesaMantenimiento : PesasManipular
    pesaCalibracion   : string
    notas             : string 
    pesasManipular    : PesasManipular
    equiposManipular  : EquiposManipular,
    pesos             : Interface_Pesos
}

export interface PesasManipular {
    k05         : string
    k10         : string
    k20         : string
    k200        : string
    k500        : string
    k1000       : string
    sustitucion : string
}

export interface EquiposManipular {
    montacargas       : boolean
    carretilla        : boolean
    ascensor          : boolean
    montacargasAzocar : boolean
}


///PROBACIPONES
interface Aprobaciones {
    aprobado : boolean
    fecha    : string
    por      : string
    notas    : string
    horaLlegada: string
}





///GPS
interface GPS {
    entrada : Marca
    salida  : Marca
    otros   : Array<Marca>
}

interface Marca {
    fecha    : string
    latitud  : string
    longitud : string
    notas?   : string
}


///HISTORIAL
interface Historial {
    fecha     : string
    actividad : string
    por       : string
}


//FIRMA
export interface Firma {
    nombre : string
    cedula : string
    firma  : string
    fecha  : string
    todos?  : boolean
}

interface Reporte {
    consecutivo : string
    fechaEnvio  : string 
    equipo      : string
    modelo      : string
    isFirma     : boolean
}

export interface Interface_Pesos{
    a01     : string
    ae2     : string,
    aJuego  : string,
    ab      : string,
    b       : string,
    bJuego  : string,
    c       : string,
    cf      : string,
    df      : string
    dnm1   : string
}



///FOR VIEW LIST
export interface ModelSSTView {
    _id: string
    consecutivo: string,
    sst: {
        fecha:string
        cliente:string
    }
    aprobaciones: {
        aprobado:boolean
    }
    asignacion: {
        asignado:boolean
    },
    checklist: {
        hecho:boolean
    }
}