import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ServiceAuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: "login",
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'side-menu',
    loadChildren: () => import('./pages/side-menu/side-menu.module').then(m => m.SideMenuPageModule),
    canActivate: [ServiceAuthGuardService]
  },
  {
    path: 'version',
    loadChildren: () => import('./pages/version/version.module').then(m => m.VersionPageModule)
  },
  {
    path: 'confirmacion',
    loadChildren: () => import('./pages/confirmacion/confirmacion.module').then(m => m.ConfirmacionPageModule)
  },
  {
    path: 'image-view',
    loadChildren: () => import('./pages/modals/image-view/image-view.module').then( m => m.ImageViewPageModule)
  },
  {
    path: '**',
    redirectTo: "login",//'login',
    pathMatch: 'full'
  },
  {
    path: 'version-detalles',
    loadChildren: () => import('./pages/modals/version-detalles/version-detalles.module').then( m => m.VersionDetallesPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules,useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
