import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-colaps',
  templateUrl: './card-colaps.component.html',
  styleUrls: ['./card-colaps.component.scss'],
})
export class CardColapsComponent implements OnInit {

  @Input() title:string = ""
  @Input() color:string = "secondary"
  @Input() hide:boolean = true;

  constructor() { }

  ngOnInit() {}

  hiden(){
    this.hide = !this.hide
  }

}
