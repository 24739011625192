import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-asing',
  templateUrl: './list-asing.component.html',
  styleUrls: ['./list-asing.component.scss'],
})
export class ListAsingComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
