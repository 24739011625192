import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { HeadChecklistComponent } from './checklist/head-checklist/head-checklist.component';
import { PipesModule } from '../pipes/pipes.module';
import { DocumentosChecklistComponent } from './checklist/documentos-checklist/documentos-checklist.component';
import { AdminCardComponent } from './administracion/admin-card/admin-card.component';
import { CardAsingComponent } from './asignaciones/card-asing/card-asing.component';
import { ListAsingComponent } from './asignaciones/list-asing/list-asing.component';
import { TablaPesasComponent } from './asignaciones/tabla-pesas/tabla-pesas.component';
import { TablaEquiposComponent } from './asignaciones/tabla-equipos/tabla-equipos.component';
import { LoadFileComponent } from './load-file/load-file.component';
import { PesasComponent } from './pesas/pesas.component';
import { CardColapsComponent } from './card-colaps/card-colaps.component';
import { ChecklistDoneComponent } from './checklist-done/checklist-done.component';
import { FirmaComponent } from './firma/firma.component';
import { EstadoEquipoComponent } from './estado-equipo/estado-equipo.component';
import { LoadImagenesComponent } from './load-imagenes/load-imagenes.component';
import { MedidaPesoComponent } from './medida-peso/medida-peso.component';
import { ResumeComponent } from './resume/resume.component';
import { CotizadorTableEquiposComponent } from './cotizador-table-equipos/cotizador-table-equipos.component';
import { CotizadorTableTotalesComponent } from './cotizador-table-totales/cotizador-table-totales.component';
import { PesasBodegaComponent } from './pesas-bodega/pesas-bodega.component';
import { PesasDoneComponent } from './pesas-done/pesas-done.component';




@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        PipesModule
    ],
    declarations: [
        HeadChecklistComponent,
        DocumentosChecklistComponent,
        AdminCardComponent,
        CardAsingComponent,
        ListAsingComponent,
        TablaEquiposComponent,
        TablaPesasComponent,
        LoadFileComponent,
        PesasComponent,
        CardColapsComponent,
        ChecklistDoneComponent,
        FirmaComponent,
        EstadoEquipoComponent,
        LoadImagenesComponent,
        MedidaPesoComponent,
        ResumeComponent,
        CotizadorTableEquiposComponent,
        CotizadorTableTotalesComponent,
        PesasBodegaComponent,
        PesasDoneComponent
    ],
    exports: [
        DocumentosChecklistComponent,
        HeadChecklistComponent,
        AdminCardComponent,
        CardAsingComponent,
        ListAsingComponent,
        TablaEquiposComponent,
        TablaPesasComponent,
        LoadFileComponent,
        PesasComponent,
        CardColapsComponent,
        ChecklistDoneComponent,
        FirmaComponent,EstadoEquipoComponent,
        LoadImagenesComponent,
        MedidaPesoComponent,
        ResumeComponent,
        CotizadorTableEquiposComponent,
        CotizadorTableTotalesComponent,
        PesasBodegaComponent,
        PesasDoneComponent

    ]
})
export class ComponentsModule { }