import { ModelEstadoEquipo } from "./model_reporte"


const arraySeleccion:ModelEstadoEquipo[] = [
    {
        title:"Cobertor",
        check:false,
        estado:""

    },
    {
        title:"Batería",
        check:false,
        estado:""

    },
    {
        title:"Adaptador",
        check:false,
        estado:""

    },
    {
        title:"Patas",
        check:false,
        estado:""

    },
    {
        title:"Teclado",
        check:false,
        estado:""

    },
    {
        title:"Plato",
        check:false,
        estado:""

    },
    {
        title:"Cableado 110V",
        check:false,
        estado:""

    },
    {
        title:"Cableado Celdas",
        check:false,
        estado:""

    },
    {
        title:"Sistema de Carga",
        check:false,
        estado:""

    },
];

export default arraySeleccion