import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-medida-peso',
  templateUrl: './medida-peso.component.html',
  styleUrls: ['./medida-peso.component.scss'],
})
export class MedidaPesoComponent implements OnInit {

  @Input() peso: string = "g";

  @Output() selected =  new EventEmitter<string>();

  constructor() { }

  ngOnInit() {}

  onChange(){
    this.selected.emit(this.peso)
  }

}
