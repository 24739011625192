import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'moneda'
})
export class MonedaPipe implements PipeTransform {

  transform(value: string, moneda: string): string {
    if (moneda == "Dólares") {
      return "$ " + value;
    } else {
      return "C$ " + value
    }
  }

}
